<div class="rectangle details">
  <div class="page-container loan-detail bgw">
    <div class="page-title">Loan Details</div>
    <div class="intro">

      Here is a summary of the details of the business loan for <strong>{{ calcNums.account.nameOfBusiness }}</strong>.
      Refer to your signed loan contract
      for additional details. If any of the information below is not accurate, please reach out to us via one of the
      contact numbers shown above.
    </div>

    <div class="amount-group">
      <div class="rec accept text-align-center">
        <div class="dollar">{{ calcNums.loanAmount | currency}}</div>
        <div class="amount">ACCEPTED LOAN AMOUNT</div>
      </div>
      <div class="rec payment text-align-center">
        <div class="dollar">{{ calcNums.paymentAmt | currency}}</div>
        <div class="amount">PAYMENT AMOUNT</div>
      </div>
    </div>

    <div class="payment-detail-group">
      <div>
        <div class="payment-title">PAYMENT FREQUENCY</div>
        <div class="payment-detail">{{ getPaymentFreqString() }}</div>
      </div>
      <div>
        <div class="payment-title">NUMBER OF PAYMENTS</div>
        <div class="payment-detail">{{ calcNums.numOfPayment }} <img src="/assets/imgs/info-icon.svg"
            (click)="showNumOfPaymentInfo()" class="info-icon">
        </div>
      </div>
      <div>
        <div class="payment-title">TOTAL PAYBACK AMOUNT</div>
        <div class="payment-detail">{{ calcNums.totalPaybackAmt | currency: 'USD':'symbol':'1.0-0' }}</div>
      </div>
      <div>
        <div class="payment-title">DISBURSEMENT AMOUNT</div>
        <div class="payment-detail">{{ calcNums.disbursement | currency}}
          <img src="/assets/imgs/info-icon.svg" (click)="showDisbursement()" class="info-icon">
        </div>

      </div>

      <div>
        <div class="payment-title">ACCOUNT RECEIVING FUNDS</div>
        <div class="payment-detail">{{ calcNums.bankAccount }}</div>
      </div>
    </div>

    <div class="payment-date-group">
      <div class="page-sub-title">Payment Date</div>
      <div class="intro" *ngIf="!paymentFreqIsDaily()">
        Payments are debited on business days only, Monday through Friday excluding holidays and weekends. 
        Your payment schedule will begin in <strong>2 business days</strong>
        <span *ngIf="!paymentFreqIsDaily()">, but you have the option to
          select a different date below. If the dates listed do not fit your needs</span>
        <span *ngIf="paymentFreqIsDaily()">. If you would like to change your payment
          date</span>, please contact us.
      </div>

      <div *ngIf="!paymentFreqIsDaily()">
        <form [formGroup]="paymentDateForm" class="payment-day-group" (keydown)="onSubmit($event)">
          <a *ngFor="let paymentDay of calcNums.paymentDayOptionList
          let i=index" [attr.data-index]="i">

            <label for="{{paymentDay.date}}" id="day-group{{i}}" (click)="checkDay(i)">
              <div id="day-box{{i}}" class="start-day-group text-align-center">
                <div class="check-icon" id="dayIcon{{i}}"></div>
                <input type="radio" id="{{paymentDay.date}}" name="requestedStartDate" value="{{paymentDay.date}}"
                  formControlName="requestedStartDate">
                <div class="every-payment-day" id="every{{i}}">
                  <span class="every"> Every </span>
                  <span class="every" *ngIf="paymentFreqIsBiWeekly()"> Other </span>
                  <span class="payment-day" *ngIf="!paymentFreqIsMonthly()"> {{paymentDay["dayName"]}} </span>
                  <span class="payment-day" *ngIf="paymentFreqIsMonthly()"> {{paymentDay["dayOfMonth"]}} of month </span>
                </div>
                <div class="start-date">
                  <span>
                    Starting
                  </span>
                  <span>
                    {{paymentDay.formatedDate}}
                  </span>
                </div>
              </div>
            </label>
          </a>
        </form>
      </div>

      <div class="payment-due-group">
        <div class="first-payment-due">
          <div class="payment-due-icon"></div>
          <div>
            <div class="first-payment-due-title">Intended First Payment Due:</div>
            <div id="first-payment-due-date">
              {{calcNums?.paymentAmt | currency}} on

              {{selectedDate}}¹
            </div>
          </div>
        </div>

        <a class="view-payment" (click)="toggleShowItem()">
          <span #viewPaymentSchedule id="view-payment" class="cal view-payment">View Payment Schedule</span>
          <img class="info-icon" #arrow [ngClass]="showItem ? 'arrow-up' : 'arrow-down'">
        </a>
      </div>

      <app-payment-schedule [requestedStartDate]='requestedStartDate' [calcNums]='calcNums'
        [paymentScheduleList]='paymentScheduleList' *ngIf="showItem">
      </app-payment-schedule>

    </div>
  </div>
</div>

<div class="disclosures-container">
  <div class="disclosures">
    <div class="page-sub-title">Important Disclosures</div>
    <div class="intro">
      At Mulligan Funding, we strongly believe in transparency. The Terms and Conditions which govern your loan are
      contained in your Loan Agreement. However, in addition, we require you to confirm and acknowledge the following
      important items in order to proceed towards funding your loan.
    </div>

    <div class="intro read">Please read the disclosures below:
    </div>

    <div class="mulligan-funding-res">
      <div class="checkmark-icon"></div>
      <div>Mulligan Funding reserves the right to file a <a class="cal ucc" id="ucc" (click)="showUCC()">UCC</a> on my
        business
        until loan is paid off in full.
      </div>
      <div class="checkmark-icon"></div>
      <div>I will not apply for or obtain any loan of this similar type, which involves any form of daily or weekly
         ACH payment, until this loan has been paid in full.</div>
      <div class="checkmark-icon"></div>
      <div>I have not recently accepted or received any other loans and do not plan on taking other loans of this
        similar type.</div>
      <div class="checkmark-icon"></div>
      <div>I do not anticipate closing my business for any reason over the next 24 months.
      </div>
      <div class="checkmark-icon"></div>
      <div>I do not plan on declaring personal or business bankruptcy.</div>
      <div class="checkmark-icon"></div>
      <div>I recognize that any misrepresentation or false statement may constitute fraud AND MAY subject me to legal
        action.</div>
    </div>
  </div>

  <div class="checkbox text-align-center acknowledgement">
    <input (click)="toggleAcknowledged()" id='read-acknowledgement' class="acknowledged" #acknowledged type="checkbox"
      name="read-acknowledgement" value="checked">
    <label class="acknowledged-disclosures" for="read-acknowledgement"> I have carefully read and acknowledged the
      disclosures listed
      above.</label>
  </div>

  <div class="btn-container save text-align-center">
    <button [disabled]="!isAcknowledged || paymentDateForm.invalid || clicked"
      [ngClass]="isAcknowledged && paymentDateForm.valid ? 'ok' : 'btn-disable'" (click)="submitLoanDetails()" #submit
      type="submit" class="btn-background">SAVE & CONTINUE
    </button>
  </div>
  <div class="note">¹ - The intended payment due date shown may differ slightly from the actual first payment due date
    because of potential funding delays. If we cannot fund on the date mentioned, we will set the first
    payment on the first available date after funding. If you have any concerns about this, you can reach out to a loan
    specialist at the contact number above or through docs@mulliganfunding.com.</div>
</div>